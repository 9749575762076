import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { FC, useCallback, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import Heading from '@/components/Heading'
import LogoIcon from '@/components/LogoIcon'
import SecurityCard from '@/components/SecurityCard'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'

const ITEMS = [
  { id: 1, color: '#4285F4', image: 'security/google' },
  { id: 2, color: '#437FCB', image: 'security/vk' },
  { id: 3, color: '#181A1F', image: 'security/tiktok' },
  { id: 4, color: '#E52D1A', image: 'security/yandex' }
]
export const Security: FC<DefaultComponentProps> = ({ className = '' }) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation()

  const toggleAnimateContent = useCallback((state: boolean) => {
    setAnimationStart(state)
  }, [])

  return (
    <Waypoint
      topOffset={'40%'}
      bottomOffset={'40%'}
      onEnter={() => toggleAnimateContent(true)}
      onLeave={({ currentPosition }) => {
        if (currentPosition === 'below') {
          toggleAnimateContent(false)
        }
      }}
    >
      <section id="partners" className={clsx(styles['section'], className)}>
        <div className={styles['section__inner']}>
          <LogoIcon className={styles['section__logo-icon']} />

          <div className={styles['section__content']}>
            <div className={styles['section__column']}>
              <div className={styles['section__column-content']}>
                <Heading className={styles['section__heading']} level={2}>
                  {t('security:heading')}
                </Heading>
                <div
                  className={styles['section__summary']}
                  dangerouslySetInnerHTML={{ __html: t('security:summary') }}
                />
              </div>
            </div>
            <div className={styles['section__column']}>
              <div className={styles['section__column-content']}>
                <ul className={styles['section__security-list']}>
                  {ITEMS.map(({ image, color, id }, index) => (
                    <li key={id} className={styles['section__security-item']}>
                      <SecurityCard
                        index={index}
                        active={animationStart}
                        image={image}
                        color={color}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Waypoint>
  )
}
