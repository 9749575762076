import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import Animation from '@/components/Animation'
import Heading from '@/components/Heading'
import LogoIcon from '@/components/LogoIcon'

import useMediaQuery from '@/hooks/useMediaQuery'
import { useWindowScroll } from '@/hooks/useWindowScroll'
import { useWindowSize } from '@/hooks/useWindowSize'

import BlockProps from '@/types/BlockProps'
import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { routes } from '@/config'

export const DependenceAwards: FC<DefaultComponentProps> = ({
  className = ''
}) => {
  const [animationStart, setAnimationStart] = useState(false)
  const [animationPlayIndex, setAnimationPlayIndex] = useState<null | number>(
    null
  )
  const { t } = useTranslation()

  const windowSizeState = useWindowSize()
  const [windowScrollState] = useWindowScroll()

  const { y: windowScrollY } = windowScrollState

  const { height: windowHeight } = windowSizeState

  const isDesktopScreen = useMediaQuery('(min-width: 960px)')

  const itemRef = useRef<any[]>([])

  const BLOCKS = useMemo(
    () =>
      t('dependence-awards:blocks', { returnObjects: true }) as BlockProps[],
    [t]
  )

  const toggleAnimateContent = useCallback((state: boolean) => {
    setAnimationStart(state)
  }, [])

  useEffect(() => {
    if (isDesktopScreen) {
      return
    }
    let currentIndex = 0
    itemRef.current.forEach(function (item, index) {
      const { top, height } = item.getBoundingClientRect()
      if (top < (windowHeight - height / 2) / 2) {
        currentIndex = index
      }
    })
    setAnimationPlayIndex(currentIndex)
  }, [isDesktopScreen, windowHeight, windowScrollY])

  return (
    <Waypoint
      topOffset={'40%'}
      bottomOffset={'40%'}
      onEnter={() => toggleAnimateContent(true)}
      onLeave={({ currentPosition }) => {
        if (currentPosition === 'below') {
          toggleAnimateContent(false)
        }
      }}
    >
      <section
        className={clsx(styles['section'], className, {
          [styles['section_animation-start']]: animationStart
        })}
      >
        <div className={styles['section__inner']}>
          <LogoIcon className={styles['section__logo-icon']} />

          <div className={styles['section__content']}>
            <Heading className={styles['section__heading']} level={2}>
              {t('dependence-awards:heading')}
            </Heading>

            <ul className={styles['section__list']}>
              {BLOCKS.map(({ heading = '', summary = '', id }, index) => (
                <li
                  key={index}
                  className={styles['section__list-item']}
                  ref={el => (itemRef.current[index] = el)}
                  onMouseEnter={() => setAnimationPlayIndex(index)}
                  onMouseLeave={() => setAnimationPlayIndex(null)}
                >
                  <div className={styles['section__list-card']}>
                    <Heading
                      className={styles['section__list-card-heading']}
                      level={4}
                    >
                      {heading}
                    </Heading>
                    <div
                      className={styles['section__list-card-summary']}
                      dangerouslySetInnerHTML={{ __html: summary }}
                    />
                    <div className={styles['section__list-card-fig']}>
                      <Animation
                        className={styles['lottie']}
                        type={id as number}
                        loop={index !== 0}
                        autoplay={false}
                        width={'auto'}
                        height={'auto'}
                        animationPlay={
                          animationStart &&
                          (index === 0 || animationPlayIndex === index)
                        }
                      />
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            <div
              className={styles['section__notice']}
              dangerouslySetInnerHTML={{
                __html: t('dependence-awards:notice', {
                  url: `${routes.SUPPORT}?${new URLSearchParams({
                    title: t('about-partner-program:affiliate_program_question')
                  }).toString()}`
                })
              }}
            />
          </div>
        </div>
      </section>
    </Waypoint>
  )
}
