import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useMemo, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import Heading from '@/components/Heading'

import BlockProps from '@/types/BlockProps'
import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'

export const Plots: FC<DefaultComponentProps> = ({ className = '' }) => {
  const { t } = useTranslation('plots')
  const [animationStart, setAnimationStart] = useState(false)

  const BLOCKS: BlockProps[] = useMemo(
    () =>
      t('blocks', {
        returnObjects: true
      }),
    [t]
  )
  const toggleAnimateContent = useCallback((state: boolean) => {
    setAnimationStart(state)
  }, [])

  return (
    <Waypoint
      topOffset={'40%'}
      bottomOffset={'40%'}
      onEnter={() => toggleAnimateContent(true)}
      onLeave={({ currentPosition }) => {
        if (currentPosition === 'below') {
          toggleAnimateContent(false)
        }
      }}
    >
      <section
        className={clsx(styles['section'], className, {
          [styles['section_animation-start']]: animationStart
        })}
      >
        <div className={styles['section__inner']}>
          <div className={styles['section__content']}>
            <div className={styles['section__fig']}>
              <img
                className={styles['section__img']}
                src={`/images/plots/pic5.png`}
                alt={t('heading') as string}
              />
            </div>
            <Heading className={styles['section__heading']} level={3}>
              {t('heading')}
            </Heading>
            <div
              className={styles['section__summary']}
              dangerouslySetInnerHTML={{ __html: t('summary') }}
            />
            <ul className={styles['section__list']}>
              {BLOCKS.map(({ heading, image }, index) => (
                <li key={index} className={styles['section__list-item']}>
                  <div className={styles['section__list-fig']}>
                    <img
                      className={styles['section__list-img']}
                      src={`/images/plots/${image}`}
                      alt={heading}
                    />
                  </div>
                  <div className={styles['section__list-heading']}>
                    {heading}
                  </div>
                  <div className={styles['section__list-line']} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </Waypoint>
  )
}
