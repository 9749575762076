import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useMemo, useState } from 'react'

import Benefit from '@/components/Benefit'
import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import Sticker from '@/components/Sticker'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { routes } from '@/config'

export const AboutPartnerProgram: FC<DefaultComponentProps> = ({
  className = ''
}) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setTimeout(() => {
      setAnimationStart(true)
    }, 300)
  }, [])

  const LIST: string[] = useMemo(
    () =>
      t('about-partner-program:list', {
        returnObjects: true
      }),
    [t]
  )

  return (
    <section
      className={clsx(styles['section'], className, {
        [styles['section_animation-start']]: animationStart
      })}
    >
      <div className={styles['section__content']}>
        <div className={styles['section__column']}>
          <div className={styles['section__column-inner']}>
            <Heading className={styles['section__heading']} level={2}>
              <span
                className={styles['section__heading-text']}
                dangerouslySetInnerHTML={{
                  __html: t('about-partner-program:heading')
                }}
              />
              <Sticker
                className={styles['section__heading-sticker']}
                after={<Icon name={'emoji/smart/40'} />}
              >
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('about-partner-program:heading_short')
                  }}
                />
              </Sticker>
            </Heading>
            <div
              className={styles['section__summary']}
              dangerouslySetInnerHTML={{
                __html: t('about-partner-program:summary')
              }}
            ></div>
            <ul className={styles['section__summary-list']}>
              {LIST.map((item, index) => (
                <li
                  key={index}
                  className={styles['section__summary-list-item']}
                  dangerouslySetInnerHTML={{ __html: item }}
                />
              ))}
            </ul>
            <div className={styles['section__auth-links']}>
              <Button
                title={t('forms:buttons.plug') as string}
                href={routes.REGISTER}
                as={Link}
                size={'l'}
                className={styles['section__button-link']}
                before={<Icon name="plus/24" />}
              >
                {t('forms:buttons.plug')}
              </Button>
              <Button
                title={t('forms:buttons.getConsultation') as string}
                href={{
                  pathname: routes.SUPPORT,
                  query: {
                    title: t('about-partner-program:affiliate_program_question')
                  },
                  external: true
                }}
                as={Link}
                className={styles['section__button-link']}
                size={'l'}
                before={<Icon name="user/24" />}
                variant={'ghost'}
              >
                {t('forms:buttons.getConsultation')}
              </Button>
            </div>
          </div>
        </div>
        <div className={styles['section__column']}>
          <div className={styles['section__column-inner']}>
            <Benefit
              className={styles['section__benefits']}
              animationStart={animationStart}
              type={6}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
