import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import Animation from '@/components/Animation'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Benefit.module.scss'

interface BenefitProps extends DefaultComponentProps {
  animationStart: boolean
  type?: number
  poster?: ReactNode
}

const Benefit: FC<BenefitProps> = ({
  className = '',
  animationStart,
  type = null,
  poster = null
}) => {
  return (
    <div className={clsx(styles['benefit'], className)}>
      <div className={styles['benefit__preview']}>
        <div className={styles['benefit__preview-in']}>
          {animationStart && type && (
            <Animation type={type} className={styles['lottie']} />
          )}
          {poster}
        </div>
      </div>
    </div>
  )
}
export default Benefit
