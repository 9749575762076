import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useEffect, useState } from 'react'

import Benefit from '@/components/Benefit'
import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { useUser } from '@/bus/user'
import { routes } from '@/config'

export const AboutNetworks: FC<DefaultComponentProps> = ({
  className = ''
}) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation()

  const { isAuthorized } = useUser()

  useEffect(() => {
    setTimeout(() => {
      setAnimationStart(true)
    }, 300)
  }, [])

  return (
    <section
      className={clsx(styles['section'], className, {
        [styles['section_animation-start']]: animationStart
      })}
    >
      <div className={styles['section__content']}>
        <div className={styles['section__column']}>
          <div className={styles['section__column-inner']}>
            <Heading className={styles['section__heading']} level={2}>
              {t('about:heading')}
            </Heading>
            <div
              className={styles['section__summary']}
              dangerouslySetInnerHTML={{ __html: t('about:summary') }}
            ></div>
            <div className={styles['section__auth-links']}>
              {isAuthorized ? (
                <Button
                  title={t('forms:buttons.services') as string}
                  href={{
                    pathname: routes.SERVICES,
                    external: true
                  }}
                  as={Link}
                  size={'l'}
                  className={styles['section__button-link']}
                  before={<Icon name="arrows/right/24" />}
                >
                  {t('forms:buttons.services')}
                </Button>
              ) : (
                <>
                  <Button
                    title={t('forms:buttons.signup') as string}
                    href={routes.REGISTER}
                    as={Link}
                    size={'l'}
                    className={styles['section__button-link']}
                    before={<Icon name="arrows/right/24" />}
                  >
                    {t('forms:buttons.signup')}
                  </Button>
                  <Button
                    title={t('forms:buttons.signin') as string}
                    href={routes.LOGIN}
                    as={Link}
                    className={styles['section__button-link']}
                    size={'l'}
                    before={<Icon name="user/24" />}
                    variant={'ghost'}
                  >
                    {t('forms:buttons.signin')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles['section__column']}>
          <div className={styles['section__column-inner']}>
            <Benefit
              className={styles['section__benefits']}
              animationStart={animationStart}
              type={5}
            />
          </div>
        </div>
      </div>
    </section>
  )
}
