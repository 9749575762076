import clsx from 'clsx'
import { FC, ReactNode } from 'react'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Sticker.module.scss'

interface StickerProp extends DefaultComponentProps {
  children: ReactNode
  after?: ReactNode
  before?: ReactNode
}
const Sticker: FC<StickerProp> = ({
  className = '',
  children,
  after = null,
  before = null
}) => {
  return (
    <div className={clsx(styles['sticker'], className)}>
      {before && <div className={styles['sticker__before']}>{before}</div>}
      <div>{children}</div>
      {after && <div className={styles['sticker__after']}>{after}</div>}
    </div>
  )
}
export default Sticker
