import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useMemo, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import LogoIcon from '@/components/LogoIcon'

import BlockProps from '@/types/BlockProps'
import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { routes } from '@/config'

export const PartnerAwards: FC<DefaultComponentProps> = ({
  className = ''
}) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation()
  const BLOCKS = useMemo(
    () =>
      t('partner-awards:blocks', {
        returnObjects: true
      }) as BlockProps[],
    [t]
  )
  const {
    i18n: { language }
  } = useTranslation()

  const toggleAnimateContent = useCallback((state: boolean) => {
    setAnimationStart(state)
  }, [])

  return (
    <Waypoint
      topOffset={'-40%'}
      bottomOffset={'40%'}
      onEnter={() => toggleAnimateContent(true)}
      onLeave={({ currentPosition }) => {
        if (currentPosition === 'below') {
          toggleAnimateContent(false)
        }
      }}
    >
      <section
        className={clsx(styles['section'], className, {
          [styles['section_animation-start']]: animationStart
        })}
      >
        <div className={styles['section__inner']}>
          <LogoIcon className={styles['section__logo-icon']} />

          <div className={styles['section__content']}>
            <Heading className={styles['section__heading']} level={2}>
              {t('partner-awards:heading')}
            </Heading>

            <ul className={styles['section__list']}>
              {BLOCKS.map(
                ({ heading = '', summary = '', id, list = null, image }) => (
                  <li key={id} className={styles['section__list-item']}>
                    <div className={styles['section__list-card']}>
                      <div className={styles['section__list-card-fig']}>
                        <Icon name={`partner-awards/${image}`} />
                      </div>
                      <Heading
                        className={styles['section__list-card-heading']}
                        level={4}
                      >
                        {heading}
                      </Heading>
                      <div
                        className={styles['section__list-card-summary']}
                        dangerouslySetInnerHTML={{ __html: summary }}
                      />
                      {list && (
                        <ul className={styles['section__list-card-list']}>
                          {list.map((item, index) => (
                            <li
                              key={index}
                              className={styles['section__list-card-list-item']}
                              dangerouslySetInnerHTML={{ __html: item }}
                            />
                          ))}
                        </ul>
                      )}
                      <Button
                        title={
                          t(
                            image === 'legal'
                              ? 'forms:buttons.requestContract'
                              : 'forms:buttons.acceptOffer'
                          ) as string
                        }
                        as={Link}
                        href={
                          image === 'legal'
                            ? routes.REGISTER
                            : routes.USER_AGREEMENTS
                        }
                        size={'l'}
                        before={<Icon name={'arrows/right/24'} />}
                        className={styles['section__list-card-button']}
                      >
                        {t(
                          image === 'legal'
                            ? 'forms:buttons.requestContract'
                            : 'forms:buttons.acceptOffer'
                        )}
                      </Button>
                    </div>
                  </li>
                )
              )}
            </ul>
          </div>
        </div>
      </section>
    </Waypoint>
  )
}
