import clsx from 'clsx'
import { FC } from 'react'

import Icon from '@/components/Icon'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './SecurityCard.module.scss'

interface SecurityCardProps extends DefaultComponentProps {
  image: string
  color: string
  active: boolean
  index: number
}

const SecurityCard: FC<SecurityCardProps> = ({
  className = '',
  image,
  color,
  active = false,
  index
}) => {
  return (
    <div
      className={clsx(styles['security-card'], className, {
        [styles['security-card_active']]: active
      })}
    >
      {/* <div
        className={styles['security-card__picture']}
        style={{
          transitionDelay: `${(index * 2) / 10}s`,
          backgroundImage: `url(${image})`
        }}
      />*/}
      <Icon
        className={styles['security-card__img']}
        name={image}
        style={{
          transitionDelay: `${(index * 2) / 10}s`
        }}
      />
      <div
        className={styles['security-card__bg']}
        style={{
          backgroundColor: color,
          transitionDelay: `${(index * 2) / 10}s`
        }}
      />
    </div>
  )
}
export default SecurityCard
