import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useCallback, useState } from 'react'
import { Waypoint } from 'react-waypoint'

import Button from '@/components/Button'
import Icon from '@/components/Icon'
import Link from '@/components/Link'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { routes } from '@/config'

export const Promocodes: FC<DefaultComponentProps> = ({ className = '' }) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation()
  const toggleAnimateContent = useCallback((state: boolean) => {
    setAnimationStart(state)
  }, [])

  return (
    <Waypoint
      topOffset={'-10%'}
      bottomOffset={'10%'}
      onEnter={() => toggleAnimateContent(true)}
      onLeave={({ currentPosition }) => {
        if (currentPosition === 'below') {
          toggleAnimateContent(false)
        }
      }}
    >
      <section
        className={clsx(styles['section'], className, {
          [styles['section_animation-start']]: animationStart
        })}
      >
        <div className={styles['section__inner']}>
          <div className={styles['section__content']}>
            <div className={styles['section__content-inner']}>
              <div className={styles['section__summary']}>
                {t('promocodes:summary')}
              </div>
              <div className={styles['section__footer']}>
                <Button
                  title={t('forms:buttons.more') as string}
                  as={Link}
                  href={routes.REGISTER}
                  className={styles['section__button']}
                  size={'l'}
                  before={<Icon name={'arrows/right/24'} />}
                >
                  {t('forms:buttons.more')}
                </Button>
              </div>
            </div>
          </div>
          <div
            className={clsx(
              styles['section__grain'],
              styles['section__dollar']
            )}
          />
          <div
            className={clsx(
              styles['section__grain'],
              styles['section__grain1']
            )}
          />
          <div
            className={clsx(
              styles['section__grain'],
              styles['section__grain2']
            )}
          />
          <div
            className={clsx(
              styles['section__grain'],
              styles['section__grain3']
            )}
          />
        </div>
      </section>
    </Waypoint>
  )
}
