import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'

import Animation from '@/components/Animation'
import Button from '@/components/Button'
import Heading from '@/components/Heading'
import Icon from '@/components/Icon'
import Link from '@/components/Link'
import LogoIcon from '@/components/LogoIcon'

import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'
import { useUser } from '@/bus/user'
import { routes } from '@/config'

export const Reward: FC<DefaultComponentProps> = ({ className = '' }) => {
  const [animationStart, setAnimationStart] = useState(false)
  const { t } = useTranslation()
  const { isAuthorized } = useUser()

  return (
    <section
      className={clsx(styles['section'], className, {
        [styles['section_animation-start']]: animationStart
      })}
    >
      <div className={styles['section__inner']}>
        <LogoIcon className={styles['section__logo-icon']} />

        <div className={styles['section__content']}>
          <div className={styles['section__column']}>
            <div className={styles['section__column-inner']}>
              <Heading className={styles['section__heading']} level={2}>
                {t('reward:heading')}
              </Heading>
              <div
                className={styles['section__summary']}
                dangerouslySetInnerHTML={{ __html: t('reward:summary') }}
              />
              <div className={styles['section__auth-links']}>
                {isAuthorized ? (
                  <Button
                    href={{
                      pathname: routes.SERVICES,
                      external: true
                    }}
                    size={'l'}
                    as={Link}
                    className={styles['section__button-link']}
                    before={<Icon name="arrows/right/24" />}
                  >
                    {t('forms:buttons.services')}
                  </Button>
                ) : (
                  <Button
                    href={routes.REGISTER}
                    as={Link}
                    size={'l'}
                    className={styles['section__button-link']}
                    before={<Icon name="plus/24" />}
                  >
                    {t('forms:buttons.connect')}
                  </Button>
                )}
                <Button
                  title={t('forms:buttons.howItWork') as string}
                  href={routes.PARTNERS}
                  as={Link}
                  className={styles['section__button-link']}
                  size={'l'}
                  before={<Icon name="arrows/right/24" />}
                >
                  {t('forms:buttons.howItWork')}
                </Button>
              </div>
            </div>
          </div>
          <div className={styles['section__column']}>
            <div className={styles['section__column-inner']}>
              <Animation
                className={styles['lottie']}
                animationPlay={true}
                type={2.1}
                autoplay={true}
                width={'auto'}
                height={'auto'}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
