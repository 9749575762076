import clsx from 'clsx'
import { useTranslation } from 'next-i18next'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'

import Animation from '@/components/Animation'
import Heading from '@/components/Heading'
import LogoIcon from '@/components/LogoIcon'

import { useWindowScroll } from '@/hooks/useWindowScroll'
import { useWindowSize } from '@/hooks/useWindowSize'

import BlockProps from '@/types/BlockProps'
import DefaultComponentProps from '@/types/DefaultComponentProps'

import styles from './Section.module.scss'

interface ProgramProps extends DefaultComponentProps {}

export const ParticipantPartnerProgram: FC<ProgramProps> = ({
  className = ''
}) => {
  const { t } = useTranslation('participant-partner-program')
  const [activeIndex, setActiveIndex] = useState<number>(-1)
  const itemRef = useRef<any[]>([])

  const windowSizeState = useWindowSize()
  const [windowScrollState] = useWindowScroll()

  const { y: windowScrollY } = windowScrollState

  const { width: windowWidth, height: windowHeight } = windowSizeState

  const BLOCKS: BlockProps[] = useMemo(
    () => t('blocks', { returnObjects: true }),
    [t]
  )

  useEffect(() => {
    let currentIndex = -1
    itemRef.current.forEach(function (item, index) {
      const { top, height } = item.getBoundingClientRect()
      if (top < (windowHeight - height / 2) / 1.5) {
        currentIndex = index
      }
    })
    setActiveIndex(currentIndex)
  }, [windowScrollY, windowWidth, windowHeight])

  return (
    <section className={clsx(styles['section'], className)}>
      <div className={styles['section__inner']}>
        <LogoIcon light className={styles['section__logo-icon']} />

        <div className={styles['section__content']}>
          <Heading className={styles['section__heading']} level={3}>
            {t('heading')}
          </Heading>
          <div className={styles['section__list']}>
            {BLOCKS.map(
              ({ heading = '', summary = '', list = null, id }, index) => (
                <div
                  ref={el => (itemRef.current[index] = el)}
                  key={index}
                  className={clsx(styles['section__list-item'], {
                    [styles['section__list-item_active']]: index === activeIndex
                  })}
                >
                  <div
                    className={styles['section__list-fig']}
                    style={{
                      transform: `translateY(${
                        index === activeIndex
                          ? 0
                          : index > activeIndex
                          ? '50%'
                          : '-50%'
                      })`
                    }}
                  >
                    <div className={styles['section__list-fig-in']}>
                      <Animation
                        className={styles['lottie']}
                        autoplay={false}
                        animationPlay={activeIndex === index}
                        type={id as number}
                        width={'auto'}
                        height={'auto'}
                      />
                    </div>
                  </div>
                  <div
                    className={styles['section__list-content']}
                    style={{
                      transform: `translateY(${
                        index === activeIndex
                          ? 0
                          : index > activeIndex
                          ? '50%'
                          : '-50%'
                      })`
                    }}
                  >
                    <Heading
                      className={styles['section__list-heading']}
                      level={4}
                    >
                      {heading}
                    </Heading>
                    <div
                      className={styles['section__list-summary']}
                      dangerouslySetInnerHTML={{ __html: summary }}
                    ></div>
                    {list && (
                      <ul className={styles['section__list-list']}>
                        {list.map((item, index) => (
                          <li
                            key={index}
                            className={styles['section__list-list-item']}
                            dangerouslySetInnerHTML={{ __html: item }}
                          />
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className={styles['section__bg']} />
    </section>
  )
}
